<template>
  <div>
    <NavTitle title="整改详情"/>
    <div class="panel" v-if="rectifyDetail">
      <div class="flex between-center">
        <PanelTitle title="整改商家" :divided="false"/>
        <div :class="['desc-status', rectifyDetail.status == 4 ? 'theme-text':'red-text']" v-if="rectifyDetail.statusDesc">{{rectifyDetail.statusDesc}}</div>
      </div>
      <div class="content">
        <div class="title-row">
          <div>商家名称：{{rectifyDetail.merchantName}}</div>
          <div class="enter-report" @click="toReportList">
            <span>商家投诉</span>
            <img src="@/assets/images/arrow-right-theme.png">
          </div>
        </div>
        <div class="title-row" v-if="rectifyDetail.entName">
          <div>企业名称：{{rectifyDetail.entName}}</div>
        </div>
        <div class="mt8" v-if="rectifyDetail.merchantContact">
          商家联系方式
          <span>{{rectifyDetail.merchantContact.name}}</span>：
          <span>{{rectifyDetail.merchantContact.content}}</span>
        </div>
        <div class="mt8">整改原因：{{rectifyDetail.reason}}</div>
        <div class="mt8">整改开始时间：{{rectifyDetail.createTime}}</div>
      </div>

      <PanelTitle class="mt24" title="整改详情" :divided="false"/>
      <div class="content">
        <div class="head-row">
          <!-- <img class="avatar" :src="rectifyDetail.merchantLogo"> -->
          <LoadImage class="avatar" :src="rectifyDetail.merchantLogo" :default="require('@/assets/images/default-avatar.png')"></LoadImage>
          <div>
            <div class="flex items-center">
              <div class="name">{{rectifyDetail.merchantName}}</div>
              <div class="tag-times" v-if="rectifyDetail.submitTimes">{{rectifyDetail.submitTimes}}</div>
            </div>
            <div class="time">{{rectifyDetail.dt}}</div>
          </div>
        </div>
        <div class="desc">{{rectifyDetail.content}}</div>
        <div class="img-list">
          <LoadImage class="img" :src="item.imgUrl" preview
          v-for="(item, index) in uploadedImg" :key="index"></LoadImage>
        </div>
        <div class="file-row" v-for="(item,index) in uploadedFile" :key="index">
          <div>文件附件：<span class="name">{{item.docName}}</span></div>
          <a class="btn-download" target="_blank" :href="item.fileUrl">
            <span>下载</span>
            <img class="icon-download" src="@/assets/images/download.png">
          </a>
        </div>

      </div>
      
      <!-- 驳回理由 -->
      <template v-if="auditData">
        <PanelTitle class="mt24" title="驳回理由" :divided="false"/>
        <div class="content">
          <div class="head-row">
            <img class="avatar" src="@/assets/images/logo-customer.png">
            <div>
              <div class="name">{{auditData.auditor}}</div>
              <div class="time">{{auditData.auditTime}}</div>
            </div>
          </div>
          <div class="desc">{{auditData.auditReason}}</div>
        </div>
      </template>
      <!-- 底部按钮 -->
      <div class="flex justify-end items-center mt16 mr20" v-if="type == 1">
        <el-button type="danger" @click="$refs.refusePopupRef.open()">审核驳回</el-button>
        <el-button class="ml16" type="primary" @click="handlePass">审核通过</el-button>
      </div>
    </div>

    <!-- 审核驳回弹窗 -->
    <Popup ref="refusePopupRef" width="600px" title="审核驳回" @confirm="handleRefuse">
      <el-input v-model="auditReason" type="textarea" rows="5" maxlength="250" placeholder="请输入驳回原因" show-word-limit></el-input>
    </Popup>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import PanelTitle from '@/components/PanelTitle'
import LoadImage from '@/components/LoadImage'
import Popup from '@/components/Popup'
import { auditRectifyDetailApi, auditRectifyApi } from '@/api/admin.js'
import { ossKeyToUrl } from '@/utils/ossKeyToUrl.js'
export default {
  name: 'RectifyDetail',
  components: {
    NavTitle,
    PanelTitle,
    LoadImage,
    Popup
  },
  data() {
    return {
      rectifyDetail: null,
      rid: this.$route.query.rid,
      type: this.$route.query.type, // 1待处理 2已完成
      uploadedImg: [],
      uploadedFile: [],
      auditReason: '',
      auditData: null,
    }
  },
  created() {
    this.getRectifyDetail()
  },
  methods: {
    getRectifyDetail() {
      auditRectifyDetailApi({ rid: this.rid }).then(res => {
        console.log('整改详情', res)
        this.rectifyDetail = res.data
        this.auditData = res.data.auditData
        const pics = res.data.pics || []
        const files = res.data.files || []
        if(res.data.merchantLogo?.startsWith('oss://')) {
          ossKeyToUrl({key: res.data.merchantLogo}).then(ossRes => {
            res.data.merchantLogo = ossRes.url
          })
        }
        if(pics.length > 0) {
          this.uploadedImg = pics.map(item => {
            ossKeyToUrl({key: item.docAddress}).then(ossRes => {
              this.$set(item, 'imgUrl', ossRes.url)
            })
            return item
          })
        }
        if(files.length > 0) {
          this.uploadedFile = files.map(item => {
            ossKeyToUrl({key: item.docAddress}).then(ossRes => {
              this.$set(item, 'fileUrl', ossRes.url)
            })
            return item
          })
        }
        if(this.auditData && this.auditData.auditorLogo.startsWith('oss://')) {
          ossKeyToUrl({key: this.auditData.auditorLogo}).then(ossRes => {
            this.auditData.auditorLogo = ossRes.url
          })
        }
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    handlePass() {
      console.log('formData', {act: 1, rid: this.rid})
      this.$confirm('确定审核通过吗').then(action => {
        if(action == 'confirm') {
          auditRectifyApi({
            'act': 1, // 1-审核通过 2-拒绝
            'rid': this.rid
          }).then(res => {
            this.$tips({message: res.msg, type:'success'})
            this.$router.go(-1)
          }).catch(err => {
            this.$tips({message: err, type:'error'})
          })
        }
      }).catch(err => {})
      
    },
    handleRefuse() {
      if(!this.auditReason.trim()) return this.$tips({message: '请填写驳回原因', type:'warning'})
      const formData = {
        'act': 2, // 1-审核通过 2-拒绝
        'rid': this.rid,
        'auditReason': this.auditReason.trim()
      }
      console.log('formData', formData)
      auditRectifyApi(formData).then(res => {
        this.$refs.refusePopupRef.close()
        this.$tips({message: res.msg, type:'success'})
        this.getRectifyDetail()
        this.$router.go(-1)
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    toReportList() {
      // this.$router.push({
      //   path: '/rectify/reportList',
      //   query: {
      //     mid: this.rectifyDetail.mid,
      //     rid: this.rectifyDetail.rid
      //   }
      // })
      this.$router.push({
        path: '/report/list',
        query: {
          mid: this.rectifyDetail.mid
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  background-color: #fff;
  border-radius: 8px;
  padding-bottom: 24px;
  margin: 16px 20px 0;
}
.desc-status {
  font-weight: bold;
  margin-right: 20px;
}
.content {
  padding: 0 20px 0 54px;
  font-size: 14px;
  .title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;
    margin-top: 8px;
  }
}
.desc {
  font-size: 14px;
  color: #505E5C;
}
.img-list {
  display: flex;
  align-items: center;
  margin-top: 8px;
  .img {
    width: 68px;
    height: 68px;
    margin: 0 8px 8px 0;
    border-radius: 4px;
    cursor: pointer;
  }
}
.head-row {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 8px;
    overflow: hidden;
  }
  .tag-times {
    padding: 2px 4px;
    font-size: 12px;
    background-color: var(--theme-color);
    color: #fff;
    border-radius: 4px;
    margin-left: 8px;
  }
  .name {
    font-size: 16px;
    font-weight: bold;
    color: #001A18;
    margin-bottom: 4px;
  }
  .time {
    font-size: 12px;
    color: #505E5C;
  }
}
.file-row {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 8px;
  .name {
    font-weight: bold;
  }
  .btn-download {
    display: flex;
    align-items: center;
    color: #08C8BD;
    margin-left: 16px;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
      margin-left: 4px;
    }
  }
}
.enter-report {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  color: var(--theme-color);
  cursor: pointer;
  img {
    width: 13px;
    height: 13px;
    margin-left: 4px;
  }
}
</style>